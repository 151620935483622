import {validRequiredByType, clearError} from './validate'

const newsletterForm = $('#newsletter-form')

function validateForm(form) {
  form.find('input').each((index, item) => {
    clearError(item)
    validRequiredByType(item)
  })
}

newsletterForm.submit(function (e) {
  const _this = $(this)
  const successInfo = _this.find('.js-success')
  const errorInfo = _this.find('.js-error')
  e.preventDefault()
  successInfo.hide()
  validateForm(_this)
  if (_this.find('.input-error').length === 0) {
    $.post(this.action, _this.serialize())
        .then(function () {
          newsletterForm[0].reset()
          successInfo.show()
        }, function () {
          errorInfo.show()
        })
  }
})
