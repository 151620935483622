function activeEmailAccount (popupContent) {
  const checkUrl = window.location.search;
  const splitUrlParameter = checkUrl.split('/');
  const findUrlParameter = splitUrlParameter.indexOf('?ActiveAccount');
  if (splitUrlParameter[findUrlParameter]) {
    $('body').append('<div class="popup-account"><div class="popup-account__content">' + popupContent + '</div></div>');
    setTimeout(function(){
      $('.popup-account').fadeOut(300);
    }, 6000);
  }
}

activeEmailAccount('DZIĘKUJEMY ZA POTWIERDZENIE ADRESU EMAIL.');