import compose from 'lodash.flowright'

const validTypes = {
  "email": validEmail,
  "checkbox": ezCheckbox
}

function validEmail(input) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const testResult = re.test(input.value)
  if (testResult) {
    return input
  } else {
    isNotValid(input)
  }
}

function validCheckbox(input) {
  if (input.checked) {
    return input
  }
}

function ezCheckbox(input) {
  const checkboxContainer = input.parentNode
  if (checkboxContainer.classList.contains('ez-checked')) {
    return validCheckbox(input)
  } else {
    isNotValid(checkboxContainer)
  }
}

function isNotValid(nodeElement) {
  nodeElement.classList.add('input-error')
}

function isNotEmpty(input) {
  if (input) {
    if (input.value !== "") {
      return input
    } else {
      isNotValid(input)
      const errorField = input.nextElementSibling
      if (errorField) {
        errorField.style.display = 'block'
      }
    }
  }
}

function isRequired(input) {
  if (input.required) {
    return input
  }
}

const keysOfTypes = Object.keys(validTypes)

function validateByType(input) {
  if (input) {
    let type = input.type
    if (keysOfTypes.indexOf(type) !== -1) {
      return validTypes[type](input)
    } else {
      return true
    }
  }
}

function clearError(nodeElement) {
  if (nodeElement.type === 'checkbox' && nodeElement.parentNode.classList.contains('input-error')) {
    nodeElement.parentNode.classList.remove('input-error')
  } else {
    nodeElement.classList.remove('input-error');
    const errorField = nodeElement.nextElementSibling
    if (errorField && errorField.classList.contains('js-required-error')) {
      errorField.style.display = 'none'
    }
  }
}

const isRequiredAndIsNotEmpty = compose(isNotEmpty, isRequired)
const validRequiredByType = compose(validateByType, isRequiredAndIsNotEmpty)

export {
  validRequiredByType,
  clearError
}