const searchMap = $('#searchMapOffers')
const rentForm = $('.form-wynajem')
const urlValue = rentForm.find('input[name="actinv"]').val()

rentForm.submit(function () {
  const obj = $(this).find('input[name="offertype"]');
  if (obj.length) {
    if (parseInt(obj.val()) == 3) {
      obj.closest('form').attr('action', obj.closest('form').find('input[name="actinv"]').val());
      obj.closest('form').find('input[name="sale_type"]').val(0);
    } else {
      obj.closest('form').attr('action', obj.closest('form').find('input[name="act"]').val());
    }
  }
});

searchMap.on('click', function () {
  let array = urlValue.split('/')
  array[array.length - 1] = ''
  rentForm.attr('action', array.join('/'));
  $(this).closest('form').submit();
});